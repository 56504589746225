var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          staticClass: "foot-item",
          style: {
            width:
              _vm.layoutType == "functional"
                ? "100%"
                : _vm.isCollapse
                ? "calc(100% - 68px)"
                : "calc(100% - 0px)",
          },
        },
        [
          _vm.process.status == "todo"
            ? [
                _vm.buttonList.find((b) => b.buttonKey == "wf_staging")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: {
                          size: "medium",
                          plain: "",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("temporary-save", true)
                          },
                        },
                      },
                      [_vm._v("暂存")]
                    )
                  : _vm._e(),
                _vm.buttonList.find((b) => b.buttonKey == "wf_pass")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("examine", true)
                          },
                        },
                      },
                      [
                        ["recall", "reject"].includes(
                          _vm.process.processIsFinished
                        )
                          ? _c("span", [_vm._v("重新提交")])
                          : _c("span", [_vm._v("通过")]),
                      ]
                    )
                  : _vm._e(),
                _vm.buttonList.find((b) => b.buttonKey == "wf_reject")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("examine", false)
                          },
                        },
                      },
                      [
                        _vm.backNodes.length > 1
                          ? _c("span", [_vm._v("驳回")])
                          : _c("span", [_vm._v("取消申请")]),
                      ]
                    )
                  : _vm._e(),
                _vm.buttonList.find((b) => b.buttonKey == "wf_transfer")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("user-select", {
                              type: "transfer",
                              checkType: "radio",
                            })
                          },
                        },
                      },
                      [_vm._v("转办")]
                    )
                  : _vm._e(),
                _vm.buttonList.find((b) => b.buttonKey == "wf_delegate")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("user-select", {
                              type: "delegate",
                              checkType: "radio",
                            })
                          },
                        },
                      },
                      [_vm._v("委托")]
                    )
                  : _vm._e(),
                _vm.buttonList.find((b) => b.buttonKey == "wf_rollback")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: { click: _vm.handleRollback },
                      },
                      [_vm._v("指定回退")]
                    )
                  : _vm._e(),
                _vm.buttonList.find((b) => b.buttonKey == "wf_terminate")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("terminate")
                          },
                        },
                      },
                      [_vm._v("终止")]
                    )
                  : _vm._e(),
                _vm.process.isMultiInstance &&
                _vm.buttonList.find((b) => b.buttonKey == "wf_add_instance")
                  ? _c(
                      "el-button",
                      {
                        class: { moreBtn: _vm.buttonList.length > 3 },
                        attrs: { size: "medium", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("user-select", {
                              type: "addInstance",
                              checkType: "checkbox",
                            })
                          },
                        },
                      },
                      [_vm._v("加签")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.permission.wf_process_withdraw &&
          _vm.process.isOwner &&
          _vm.process.isReturnable &&
          !["recall", "reject"].includes(_vm.process.processIsFinished)
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("withdraw")
                    },
                  },
                },
                [_vm._v("撤销")]
              )
            : _vm._e(),
          _vm.buttonList.find((b) => b.buttonKey == "wf_print")
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("print")
                    },
                  },
                },
                [_vm._v("打印")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "medium", loading: _vm.loading },
              on: { click: _vm.closeCurrentPage },
            },
            [_vm._v("返回")]
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.nodeVisible,
            "append-to-body": "",
            title: "选择回退节点",
          },
          on: {
            "update:visible": function ($event) {
              _vm.nodeVisible = $event
            },
          },
        },
        [
          _vm.nodeVisible
            ? _c("avue-form", {
                attrs: { option: _vm.nodeOption },
                on: { submit: _vm.handleNodeSubmit },
                model: {
                  value: _vm.nodeForm,
                  callback: function ($$v) {
                    _vm.nodeForm = $$v
                  },
                  expression: "nodeForm",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }