<template>
  <div>
    <el-row class="foot-item" :style="{width: layoutType == 'functional'?'100%':isCollapse? 'calc(100% - 68px)': 'calc(100% - 0px)' }">
      <template v-if="process.status == 'todo'">
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_staging')"
                   size="medium"
                   plain
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('temporary-save', true)">暂存</el-button>
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_pass')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('examine', true)">
          <span v-if="['recall', 'reject'].includes(process.processIsFinished)">重新提交</span>
          <span v-else>通过</span>
        </el-button>
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_reject')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('examine', false)">
          <span v-if="backNodes.length > 1">驳回</span>
          <span v-else>取消申请</span>
        </el-button>
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_transfer')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('user-select', {type: 'transfer', checkType: 'radio'})">转办</el-button>
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_delegate')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('user-select', {type: 'delegate', checkType: 'radio'})">委托</el-button>
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_rollback')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="handleRollback">指定回退</el-button>
        <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_terminate')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('terminate')">终止</el-button>
        <el-button v-if="process.isMultiInstance && buttonList.find(b => b.buttonKey == 'wf_add_instance')"
                   size="medium"
                   :class="{'moreBtn': buttonList.length>3}"
                   :loading="loading"
                   v-on:click="$emit('user-select', {type: 'addInstance', checkType: 'checkbox'})">加签</el-button>
      </template>
      <el-button v-if="permission.wf_process_withdraw && process.isOwner && process.isReturnable && !['recall', 'reject'].includes(process.processIsFinished)"
                 size="medium"
                 :loading="loading"
                 v-on:click="$emit('withdraw')">撤销</el-button>
      <el-button v-if="buttonList.find(b => b.buttonKey == 'wf_print')"
                 size="medium"
                 :loading="loading"
                 v-on:click="$emit('print')">打印</el-button>
      <el-button size="medium"
                 :loading="loading"
                 v-on:click="closeCurrentPage">返回</el-button>
    </el-row>
    <el-dialog :visible.sync="nodeVisible"
               append-to-body
               title="选择回退节点">
      <avue-form v-if="nodeVisible"
                 v-model="nodeForm"
                 :option="nodeOption"
                 v-on:submit="handleNodeSubmit"></avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { backNodes } from '@/api/plugin/workflow/process'

import { mapGetters } from 'vuex'

export default {
  name: 'wf-button',
  computed: {
    ...mapGetters(['isCollapse', 'permission',"layoutType",'themeColor']),
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    buttonList: {
      type: Array,
      default: () => {
        return []
      }
    },
    process: Object,
    comment: String
  },
  data() {
    return {
      nodeVisible: false,
      nodeForm: {},
      nodeOption: {
        column: [{
          label: '节点',
          prop: 'nodeId',
          type: 'select',
          props: {
            label: 'nodeName',
            value: 'nodeId'
          },
          span: 24,
          rules: [{ required: true, message: '请选择回退节点', trigger: 'change' }]
        }]
      },
      backNodes: []
    }
  },
  watch: {
    'process.taskId': {
      handler(val) {
        if (val) {
          backNodes({ taskId: val }).then(res => {
            const list = res.data.data
            this.backNodes = list
            this.findObject(this.nodeOption.column, 'nodeId').dicData = list
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    closeCurrentPage() {
      window.close();
    },
    handleRollback() { // 指定回退
      if (!this.comment) {
        this.$message.error("请填写批复意见")
        return
      }
      this.nodeVisible = true
    },
    handleNodeSubmit() { // 指定回退确定
      const { nodeId } = this.nodeForm
      this.$emit('rollback', nodeId)
    },
  }
}
</script>

<style lang="scss" scoped>
//.foot-item {
//  position: fixed;
//  bottom: 0;
//   right: 0;
//  z-index: 101;
//  height: 66px;
//  background-color: #fff;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  -webkit-transition: 0.3s;
//  transition: 0.3s;
//  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//}

.foot-item {
  display: flex;

  ::v-deep .el-button:focus,
  ::v-deep .el-button:hover {
    color: #303133;
    border-color: #f6f7fa;
    background-color: #f6f7fa;
  }
}
//.el-button {
//  width: 140px;
//  height: 32px;
//  font-size: 12px;
//  margin: 0 12px;
//}
.el-button.moreBtn{
  width: 100px;
}

  .el-button--primary{
    background-color: #0070FF!important;
  }
  .el-button--success{
    background-color: #009A02!important;
  }
  .el-button--danger{
    background-color: #D6000F!important;
  }

.el-button--primary.is-plain{
  color: #ffffff;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0070FF;
}
.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
  background: #DDEFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0070FF;
}

</style>
